<template>
  <div id="layout-header" :class="{ fixed: false, hidden: false }"></div>
</template>

<script>
var preD = function (e) {
  e.preventDefault();
};

export default {
  name: "layout-header",

  data() {
    return {};
  },
  mounted() {},

  beforeDestroy() {},
  methods: {},
};
</script>



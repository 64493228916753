import Cookies from 'js-cookie'
import store from '../store'
import { start, stop } from "@/utils/signalR";
const TokenKey = 'zlblog_token' //** 当前登陆人的token信息 */
const guid = "6C85B589-26A2-D160-42A1-3A3471135D84";
/**
 * 获取token信息
 */
export function getToken() {
    var data = Cookies.get(TokenKey);
    return data
}

/**、
 * 写入token
 */
export function setToken(token) {
    var inFifteenMinutes = new Date(new Date().getTime() + 1 * 180 * 1000);//一个小时的token
    let Minutesfitee = parseInt(inFifteenMinutes.getTime() / 1000);
    let paymentRunTime = 9000;
    localStorage.setItem('time', paymentRunTime)
    TimeReduction();
    Cookies.set(TokenKey, token, { expires: Minutesfitee }) //添加token
}


//写入
export function Setguid(url) {
    Cookies.set(guid, url);
}

export function Getguid() {
    return Cookies.get(guid);
}

export function remove() {
    return Cookies.get(guid);
}


/**
 *  开始计时器
 */
export function TimeReduction() {
    let timerId = setInterval(() => {
        const hasToken = getToken()//获取当前token 
        if (!hasToken) {
            clearInterval(timerId)
            localStorage.removeItem('time')
            removeToken();
        }
        var paymentRunTime = localStorage.getItem('time');//获取 paymentRunTime 
        if (paymentRunTime == null) {
            clearInterval(timerId)
        }
        if (paymentRunTime == 0) {
            clearInterval(timerId)
            localStorage.removeItem('time')
            removeToken();
        }
        paymentRunTime -= 1;
        localStorage.setItem('time', paymentRunTime)
    }, 1300);
}

/**
 * 删除token信息
 */
export function removeToken() {
    localStorage.removeItem('time')
    store.dispatch('SET_loginIs', false); //写入是否登录 
    stop().then(res => {
    })
    // store.dispatch("SET_ReplynoticeIsclike", false);
    // store.dispatch("SET_ReplynoticeData", undefined);
    // store.dispatch("SET_MessageIsclike", false);//清除通知消息
    // store.dispatch("SET_MessageData", undefined);//清除通知消息
    Cookies.remove(TokenKey)
    // window.reload(); //刷新界面
}


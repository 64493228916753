import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// 略:后台获取系统运行时间
const runAt = '1615348371000';
let timer = null;
const state = {
}
const mutations = {
}
const actions = {
}
const getters = {
}
export default new Vuex.Store({
})

<template>
  <div id="layout-footer">
    
  </div>
</template>

<script>
export default {
  name: "layout-footer",

  data() {
    return {
      socials: [],
      streamModel: [],
    };
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="less">
</style>
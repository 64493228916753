<template>
  <div id="app" v-cloak>
    <layout-header></layout-header>
    <layout-body></layout-body>
    <layout-footer></layout-footer>
  </div>
</template>
<script>
import layoutHeader from "@/components/layout/layout-header";
import layoutBody from "@/components/layout/layout-body";
import layoutFooter from "@/components/layout/layout-footer";
export default {
  name: "app",
  components: {
    layoutHeader,
    layoutBody,
    layoutFooter,
  },
 
  //===========================下面是解决刷新页面丢失vuex数据
  created() {
   
  },
};
</script>
<style lang="less">
#app {
  font-size: 17px;
  color: #6f6f6f
}
</style>

<template>
  <div id="layout-body">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "layout-body",
  data() {
    return {
      minHeight: 600,
    };
  },
};
</script>

<style scoped>
</style>

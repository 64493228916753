import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { getToken, remove } from '../../src/utils/auth' // get token from cookie
import { start, stop } from "@/utils/signalR";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { title: '首页' }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


/**
 * 
 *  router 钩子函数
 */
router.beforeEach(async (to, from, next) => {

    document.title = "小仙女"
    next();
})
router.afterEach((to, from) => {
})
export default router
